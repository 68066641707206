<template>
  <div class="app-item app-content">
    <el-row :gutter="10" class="flex">
      <el-col>
        <span>我的积分:</span>
        <strong>{{integral}}</strong>
      </el-col>
      <router-link :to='{name:"Vip"}'>
        <el-col class="open-vip">
          <el-button plain>开通VIP</el-button>
        </el-col>
      </router-link>
    </el-row>
    <div class="table-content">
      <h4>积分记录</h4>
      <el-table style="width: 100%" :data="tableData">
        <el-table-column prop="created_at" label="发生时间" align="center"></el-table-column>
        <el-table-column prop="integral" label="收入/支出" align="center"></el-table-column>
        <el-table-column prop="detail" label="详情" align="center">
          <template slot-scope="props">
            <p>{{ props.row.order.desc }}</p>
            <p>订单号:{{ props.row.order_no }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="status_desc" label="状态" align="center"></el-table-column>
      </el-table>
      <Page :total="total" @goTo="goTo"></Page>
    </div>
  </div>
</template>
<script>
import Page from '../../components/Page.vue'
export default {
  components: { Page },
  data () {
    return {
      total: 0,
      tableData: [],
      integral: null
    }
  },
  methods: {
    // 获取积分列表
    getPoints () {
      this.$store.dispatch('getPoints').then(res => {
        // console.log('积分：', res)
        this.total = res.total
        this.integral = res.integral
        this.tableData = res.integrals.data
        // console.log(this.tableData)
      })
    },
    // 翻页
    goTo (page) {
      this.getPoints(`/api/web/integrals?page=${page}`)
    }
  },
  mounted () {
    this.getPoints()
  }
}
</script>
<style lang="less" scoped>
.app-item {
  .active{
        border:none
      }
  .el-row {
    height: 7rem;
    background: @global-personal-background;
    padding: 0 3rem;
    .el-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      strong {
        color: red;
        font-size: 1.8rem;
        letter-spacing: 2px;
      }
    }
    .open-vip {
      align-items: flex-end;

    }
  }
  .table-content {
    padding: 0 3rem;
  }
}
.el-table .warning-row {
  background: oldlace;
}

@media screen and (max-width: 700px) {
  .app-item {
    .table-content {
      padding: 0;
    }
  }
}
</style>
